import { createSlice } from "@reduxjs/toolkit";


const initialState = {
    success: false,
    token: null,
    user: {}
  };

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        login: (state, { payload }) => {
            state.success = payload.success;
            state.token = payload.token;
            state.user = payload.user;
        },
        logout: (state) => {
            state.success = false;
            state.token = null;
            state.user = {};
        }
    }
})

export const { login, logout } = authSlice.actions;
export default authSlice.reducer;