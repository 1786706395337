import styled from '@emotion/styled';
import { Box } from '@mui/material';
import React from 'react'

function Settings() {
  return (
    <>
        <Heading>
            Settings
        </Heading>
    </>
  )
}

export default Settings;

const Heading = styled(Box)(() => ({
    fontSize: '32px',
    fontWeight: '600',
    color: '#333333'
  }));