import React from 'react';
import Button from '@mui/material/Button';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import { Input } from 'antd';
import { ReactComponent as SearchIcon } from '../../../Assets/icons/SearchIcon.svg';
import { ReactComponent as MoreIcon } from '../../../Assets/icons/MoreIcon.svg';
import UserImage from '../../../Assets/images/UserImage.png';
import styled from '@emotion/styled';
import { Box, Typography } from '@mui/material';
import { useSelector } from 'react-redux';

function Header() {
  const { user } = useSelector(state => state?.auth);

    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);
    const handleClick = (event) => {
      setAnchorEl(event.currentTarget);
    };
    const handleClose = () => {
      setAnchorEl(null);
    };

  return (
    <MainContainer>
        <div>
            {/* <Input placeholder="Search" className='search_input' prefix={<SearchIcon />} /> */}
        </div>
        <UserContainer>
            <div><img src={UserImage} width={44} height={44} style={{borderRadius: '50%'}} /></div>
            <UserDropdown
                id="basic-button"
                // aria-controls={open ? 'basic-menu' : undefined}
                aria-haspopup="true"
                // aria-expanded={open ? 'true' : undefined}
                // onClick={handleClick}
            >
                <div>
                    <NameText>{user?.name}</NameText>
                    {/* <TypeText>Admin</TypeText> */}
                </div>
                {/* <div><MoreIcon width={27} height={27} style={{transform: anchorEl ? 'rotate(-180deg)' : 'rotate(0deg)', transition: 'all .2s'}} /></div> */}
            </UserDropdown>

        </UserContainer>
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            // MenuListProps={{
            // 'aria-labelledby': 'basic-button',
            // }}
            PaperProps={{
                elevation: 0,
                sx: {
                  overflow: 'visible',
                  filter: 'drop-shadow(0px 2px 8px rgba(0,0,0,0.32))',
                  mt: 1.5,
                  '& .MuiAvatar-root': {
                    width: 32,
                    height: 32,
                    ml: -0.5,
                    mr: 1,
                  },
                //   '&::before': {
                //     content: '""',
                //     display: 'block',
                //     position: 'absolute',
                //     top: 0,
                //     right: 10,
                //     width: 10,
                //     height: 10,
                //     bgcolor: 'background.paper',
                //     transform: 'translateY(-50%) rotate(45deg)',
                //     zIndex: 0,
                //   },
                },
              }}
            
        >
            <MenuItem onClick={handleClose}>
                <MenuText>Profile</MenuText>
            </MenuItem>
            <MenuItem onClick={handleClose}>
                <MenuText>Logout</MenuText>
            </MenuItem>
        </Menu>
    </MainContainer>
  )
}

export default Header;

const MainContainer = styled(Box)(() => ({
    padding: '0px 30px 0px 0px',
    background: '#fff',
    height: '70px',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  }));
const UserContainer = styled(Box)(() => ({
    display: 'flex',
    gap: '18px',
    alignItems: 'center'
  }));
const UserDropdown = styled(Box)(() => ({
    display: 'flex',
    gap: '30px',
    // cursor: 'pointer',
    alignItems: 'center'
  }));
const NameText = styled(Box)(() => ({
    fontWeight: '600',
    fontSize: '14px',
    color: '#404040',
  }));
const TypeText = styled(Box)(() => ({
    fontWeight: '400',
    fontSize: '12px',
    color: '#565656'
  }));
const MenuText = styled(Box)(() => ({
    fontWeight: '400',
    fontSize: '15px',
    width: '85px',
    width: '100px',
    textAlign: 'center'
  }));