import axios from "axios";
export const apiLogin = async (endpoint, data) => {
    let location = window.location.origin

    const BASE_URL = "https://api.pencil.pk";
    
    try {
        let result = axios({
            url: `${BASE_URL}/${endpoint}`,
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                Accept: 'application/json',
                withCredentials: true
            },
            data: data
        }).then((res) => res)
        return (result)
    } catch (error) {
        console.error("POST API FAILED!");
    }
}