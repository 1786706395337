import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { Box } from "@mui/material";
import { Divider, Layout, Menu, theme } from "antd";
import DashboardLogo from "../../../Assets/images/DashboardLogo.png";
import { ReactComponent as DashIcon } from "../../../Assets/icons/DashIcon.svg";
import { ReactComponent as AgencyIcon } from "../../../Assets/icons/AgencyIcon.svg";
import { ReactComponent as UserIcon } from "../../../Assets/icons/UserIcon.svg";
import { ReactComponent as SettingIcon } from "../../../Assets/icons/SettingIcon.svg";
import { ReactComponent as LogoutIcon } from "../../../Assets/icons/LogoutIcon.svg";
import { useLocation, useNavigate } from "react-router";
import Header from "../Header/Header";
import { useDispatch } from "react-redux";
import { logout } from "../../../Redux/authSlice";
import { ArticleOutlined } from "@mui/icons-material";
import { TuneOutlined } from "@mui/icons-material";

// const { Header, Content, Footer, Sider } = Layout;
const { Content, Footer, Sider } = Layout;

const sideNav = [
  {
    name: "Dashboard",
    icon: DashIcon,
  },
  {
    name: "Agencies",
    icon: AgencyIcon,
  },
  {
    name: "Users",
    icon: UserIcon,
  },
  {
    name: "News Articles",
    icon: <ArticleOutlined style={{ fontSize: "21.33px" }} />,
  },
  {
    name: "Control Ads",
    icon: <TuneOutlined style={{ fontSize: "21.33px" }} />,
  },
];
const sideNav2 = [
  // {
  //   name: 'Settings',
  //   icon: SettingIcon
  // },
  {
    name: "Logout",
    icon: LogoutIcon,
  },
];

const navbar = 240;

function LeftNav({ children }) {
  const location = useLocation();
  const dispatch = useDispatch();
  const nav = useNavigate();

  const [selectedKeys, setSelectedKeys] = useState(
    location?.pathname?.substring(1)
  );

  const handleClick = (data) => {
    if (data?.name === "Logout") {
      dispatch(logout());
      nav("/login");
      return false;
    }

    if (data?.name?.includes(" ")) {
      setSelectedKeys([`${data?.name?.replace(/\s+/g, "-")?.toLowerCase()}`]);
      nav(`/${data?.name?.replace(/\s+/g, "-")?.toLowerCase()}`);
    } else {
      setSelectedKeys([`${data?.name?.toLowerCase()}`]);
      nav(`/${data?.name?.toLowerCase()}`);
    }
  };

  return (
    <div>
      <Layout hasSider>
        <Sider
          width={navbar}
          style={{
            overflow: "auto",
            height: "100vh",
            position: "fixed",
            left: 0,
            top: 0,
            bottom: 0,
            background: "#fff",
          }}
        >
          <Logo>
            <img
              src={DashboardLogo}
              onClick={() => {
                nav("/dashboard");
                setSelectedKeys([`dashboard`]);
              }}
              style={{ cursor: "pointer", height: "45px" }}
            />
            <LogoText
              onClick={() => {
                nav("/dashboard");
                setSelectedKeys([`dashboard`]);
              }}
              style={{ cursor: "pointer" }}
            >
              Pencil News
            </LogoText>
          </Logo>

          <Menu
            style={{ marginTop: "30px" }}
            selectedKeys={selectedKeys}
            // items={items}
            // onClick={handleClick}
          >
            {sideNav?.map((item, index) => {
              return (
                <div
                  key={index}
                  style={{ display: "flex", alignItems: "center" }}
                >
                  <div
                    style={{
                      width: "9px",
                      height: "47.85px",
                      marginLeft: "-5px",
                      borderRadius: "10px",
                      background:
                        item?.name?.toLowerCase() === selectedKeys.toString() ||
                        item?.name?.replace(/\s+/g, "-")?.toLowerCase() ===
                          selectedKeys.toString()
                          ? "#553E97"
                          : "transparent",
                    }}
                  ></div>
                  <Menu.Item
                    selectedkeys={
                      item?.name?.toLowerCase() === selectedKeys.toString() ||
                      item?.name?.replace(/\s+/g, "-")?.toLowerCase() ===
                        selectedKeys.toString()
                        ? "true"
                        : "false"
                    }
                    key={item?.name?.toLowerCase()}
                    onClick={() => handleClick(item)}
                    icon={
                      item?.name?.includes("News") ||
                      item?.name?.includes("Ads")
                        ? item?.icon
                        : React.createElement(item?.icon)
                    }
                  >
                    {item?.name}
                  </Menu.Item>
                </div>
              );
            })}
          </Menu>
          <div style={{ marginTop: "auto", marginBottom: "30px" }}>
            <Divider />
            {/* <Menu
                  selectedKeys={selectedKeys}
                  items={footerItems}
                  onClick={handleClick}
                /> */}
            <Menu selectedKeys={selectedKeys}>
              {sideNav2?.map((item, index) => {
                return (
                  <div
                    key={index}
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <div
                      style={{
                        width: "9px",
                        height: "47.85px",
                        marginLeft: "-5px",
                        borderRadius: "10px",
                        background:
                          item?.name?.toLowerCase() === selectedKeys.toString()
                            ? "#553E97"
                            : "transparent",
                      }}
                    ></div>
                    <Menu.Item
                      selectedkeys={
                        item?.name?.toLowerCase() === selectedKeys.toString()
                          ? "true"
                          : "false"
                      }
                      key={item?.name?.toLowerCase()}
                      onClick={() => handleClick(item)}
                      icon={React.createElement(item?.icon)}
                    >
                      {item?.name}
                    </Menu.Item>
                  </div>
                );
              })}
            </Menu>
          </div>
        </Sider>
        <Layout
          style={{
            marginLeft: navbar,
          }}
        >
          <Header />
        </Layout>
      </Layout>

      <BodySection navbar={navbar}>{children}</BodySection>
    </div>
  );
}

export default LeftNav;

const Logo = styled(Box)(({ theme, navbar }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "70px",
}));
const LogoText = styled(Box)(({ theme, navbar }) => ({
  fontSize: "18px",
  fontWeight: "600",
  marginLeft: "10px",
}));
const BodySection = styled(Box)(({ theme, navbar }) => ({
  width: `calc(100vw - ${navbar})`,
  minHeight: "calc(100vh - 70px)",
  marginLeft: navbar,
  padding: "30px 24px",
  transition: "all .3s",
}));
