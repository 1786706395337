import React, { useEffect, useState } from 'react'
import { Button, Form, Input, Checkbox } from "antd";
import { CircularProgress, Container, Grid } from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import VisibilityOffOutlinedIcon from "@mui/icons-material/VisibilityOffOutlined";
import { ReactComponent as PencilNewsLogo } from '../../Assets/icons/Logo.svg';
import { useNavigate } from 'react-router';
import toast from 'react-hot-toast';
import { apiLogin } from '../../Services/apiLogin';
import { useDispatch, useSelector } from 'react-redux';
import { login } from '../../Redux/authSlice';

function Login() {

  const { success } = useSelector(state => state?.auth);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [loader, setLoader] = useState(false);

  useEffect(() => {
    success && navigate('/dashboard');
  }, [])


  const onFinish = (data) => {
    setLoader(true);
    apiLogin( 'api/admin/login' , data).then((res) => {
      if (res?.data?.success === true) {
        dispatch(login(res?.data));
        navigate('/dashboard');
        setLoader(false);
      }
    }).catch((err)=>{
      setLoader(false);
      toast.error(
        `${
          err?.response?.data?.msg
            ? err?.response?.data?.msg
            : err?.response?.data?.validation?.body?.message
            ? err?.response?.data?.validation?.body?.message
            : "Login"
        } Error`
      );
    })
  };

  const isValidEmail = (email) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return emailRegex.test(email);
  };
  
  return (
    <>
      <div style={{display: 'flex', justifyContent: 'center', margin: '50px auto'}}>
        <PencilNewsLogo  style={{width: '235px', marginRight: '-65px'}} />
      </div>
      <Grid
        item
        xs={12}
        style={{
          // height: "644px",
          height: "auto",
          maxWidth: "594px",
          padding: '50px 30px',
          marginInline: "auto",
          background: 'white',
          border: '1px solid #EAEAEA',
          borderRadius: '17px',
          marginBottom: '30px'
        }}
      >
        <Container className="signin-modal">
            <h3
              style={{
                fontSize: "clamp(34px, 3vw, 22px)",
                fontWeight: "600",
                color: "#090914",
                marginBottom: "15px",
                textAlign: "center"
              }}
            >
              Sign In
            </h3>
            <span style={{fontSize: 'clamp(16px, 3vw, 14px)', color: '#A7A7A7', fontWeight: '500', textAlign: "center"}}>
                <p className='text-align-center1 mb-0'>Welcome back! Please enter your details.</p>
            </span>

            <Form
              // form={form}
              name="control-hooks"
              onFinish={onFinish}
              onFinishFailed={({ errorFields }) => {
                //   console.log(errorFields.map(field => field.errors.toString().includes('consecutive')));
                //   console.log(errorFields);
                const consecutiveSpacesError = errorFields.find((field) =>
                  field.errors.toString().includes("consecutive spaces")
                );
                if (consecutiveSpacesError) {
                  // message.error("Please Remove Consecutive Spaces!")
                } else {
                  // message.error("Please Fill Required Fields!");
                  toast.error("Please Fill Required Fields!");
                }
              }}
              style={{ margin: "40px 0px 0px 0px" }}
            >
              <Form.Item
                name="email"
                rules={[
                  {
                    whitespace: true,
                    required: true,
                    validator: (_, value) => {
                      if (!value || value.trim() === "") {
                        return Promise.reject("please enter email");
                      } else if (/\s{2,}/.test(value)) {
                        return Promise.reject(
                          "please remove consecutive spaces"
                        );
                      }else if (!isValidEmail(value)) {
                        return Promise.reject("please enter a valid email");
                      }
                      return Promise.resolve();
                    },
                  },
                ]}
                className="custom-border"
              >
                <div>
                    <span style={{fontSize: '14px', fontWeight: '500'}}>Email<span style={{color: '#D10135', fontFamily: 'Inter', fontSize: '16px'}}>*</span></span>
                  <Input
                    placeholder="Email"
                    maxLength={50}
                  />
                </div>
              </Form.Item>
              <Form.Item
                name="password"
                rules={[
                  {
                    whitespace: true,
                    required: true,
                    validator: (_, value) => {
                      if (!value) {
                        return Promise.reject("please enter password");
                      } else if (value.length < 8) {
                        return Promise.reject(
                          "password length must be atleast 8 characters long"
                        );
                      }

                      return Promise.resolve();
                    },
                  },
                ]}
                className="custom-border"
              >
                <div>
                  <div style={{display: 'flex', justifyContent: 'space-between'}}>
                    <span style={{fontSize: '14px', fontWeight: '500'}}>Password<span style={{color: '#D10135', fontFamily: 'Inter', fontSize: '16px'}}>*</span></span>
                    <p
                      className="mb-0"
                      style={{
                        fontSize: "clamp(14px, 2vw, 10px)",
                        color: "#553E97",
                        fontWeight: "500",
                        margin: '0px'
                      }}
                    >
                      <span
                        onClick={() => {
                          // setSignInModal({ open: false, data: "" });
                          // setForgotModal({ open: true, data: "" });
                        }}
                        style={{ cursor: "pointer" }}
                      >
                        Forgot password?
                      </span>
                    </p>
                  </div>
                  <Input.Password
                    placeholder="Password"
                    iconRender={(visible) =>
                      visible ? (
                        <VisibilityOutlinedIcon
                          style={{
                            fontSize: "20px",
                            cursor: "pointer",
                            color: "#12121280",
                            marginTop: "2px",
                          }}
                        />
                      ) : (
                        <VisibilityOffOutlinedIcon
                          style={{
                            fontSize: "20px",
                            cursor: "pointer",
                            color: "#12121280",
                            marginTop: "2px",
                          }}
                        />
                      )
                    }
                  />
                </div>
              </Form.Item>

              <Checkbox
                onChange={(val) => console.log(val)}
                style={{
                  fontSize: "clamp(14px, 3vw, 13px)",
                  color: "#858585",
                  fontWeight: "500",
                  marginBottom: '40px'
                }}
              >Remember me</Checkbox>

              <Button
                htmlType="submit"
                // className="form-button"
                className="btn form-button"
                style={{ height: "45px", width: "100%", borderRadius: "9px", pointerEvents: loader && 'none'}}
              >
                {
                  loader ? <CircularProgress size={23} /> : 'Sign in'
                }
              </Button>

              {/* <p
                className="text-align-center1 mb-0"
                style={{
                  fontSize: "clamp(15px, 3vw, 13px)",
                  color: "#989898",
                  fontWeight: "400",
                  textAlign: 'center',
                  marginTop: '20px'
                }}
              >
                Don't have an account?{" "}
                <span
                  // onClick={() => setSignInModal({ open: false, data: "" })}
                  style={{ color: "#7C7C7C", cursor: "pointer",fontWeight: "600" }}
                >
                  Sign up
                </span>
                .
              </p> */}
            </Form>
          </Container>
      </Grid>
    </>
  )
}

export default Login;