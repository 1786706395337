import React from 'react';
import { ThreeDots } from 'react-loader-spinner';
import { Box } from '@mui/material';

function SkeletonLoader() {
  return (
    <Box
      sx={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        height: '60vh',
        width: '100%'
      }}
    >
      <ThreeDots
        height="100"
        width="100"
        radius="9"
        // color="#553E97"
        color="#6d55b5"
        ariaLabel="three-dots-loading"
        wrapperStyle={{}}
        wrapperClassName=""
        visible={true}
      />
    </Box>
  );
}

export default SkeletonLoader;