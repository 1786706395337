// import styled from "@emotion/styled";
import { alpha, styled } from "@mui/material/styles";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import toast from "react-hot-toast";
import SkeletonLoader from "../../Components/SkeletonLoader";
import Switch from "@mui/material/Switch";
import axios from "axios";

function ControlAds() {
  const auth_state = useSelector((state) => state?.auth);

  const [adsControl, setAdsControl] = useState([]);
  const [paginationLoader, setPaginationLoader] = useState(false);
  const [frequency, setFrequency] = useState("");
  const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);

  useEffect(() => {
    getOrUpdateAdsControl();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function getOrUpdateAdsControl(isUpdate = false) {
    const baseUrl = "https://api.pencil.pk/api/admin/";
    const endpoint = isUpdate ? "adControlUpdate" : "adControl";

    const method = isUpdate ? "PUT" : "GET";

    setPaginationLoader(true);

    try {
      const url = `${baseUrl}${endpoint}`;
      const res = await axios({
        method,
        url,
        headers: {
          authorization: auth_state?.token,
        },
      });

      const data = await res.data;
      setAdsControl(data.adControl);
      setFrequency(data.adControl.interstatialAdsFrequency);
      setPaginationLoader(false);

      isUpdate &&
        toast.success(
          `${
            data.adControl.showAds
              ? "Ads enabled successfully"
              : "Ads disabled successfully"
          }`
        );
    } catch (err) {
      console.error(err);
      toast.error(isUpdate ? "Failed to update ads" : "Failed to get ads");
    }
  }

  async function updateIndividual(type) {
    const baseUrl = "https://api.pencil.pk/api/admin/";
    const endpoint =
      type == "banner"
        ? "bannerAdControlUpdate"
        : type == "interStatial"
        ? "interstatialAdControlUpdate"
        : type == "frequency"
        ? "interstatialAdFrequencyUpdate"
        : "";

    const method = "PUT";
    const body = type === "frequency" ? { adFrequency: frequency } : {};

    setPaginationLoader(true);

    try {
      const url = `${baseUrl}${endpoint}`;
      const res = await axios({
        method,
        url,
        headers: {
          authorization: auth_state?.token,
        },
        data: body,
      });

      const data = await res.data;
      console.log("data", data);
      setAdsControl(data.adControl);
      setFrequency(data.adControl.interstatialAdsFrequency);
      if (data.success == true && type == "frequency") {
        setIsSubmitEnabled(false);
      }
      setPaginationLoader(false);

      if (type == "banner") {
        toast.success(
          `${
            data.adControl.showBannerAds
              ? "Banner Ads enabled successfully"
              : "Banner Ads disabled successfully"
          }`
        );
      } else if (type == "interStatial") {
        toast.success(
          `${
            data.adControl.showInterstatialAds
              ? "Interstatial Ads enabled successfully"
              : "Interstatial Ads disabled successfully"
          }`
        );
      } else if (type == "frequency") {
        toast.success("Interstatial Ads frquency updated");
      }
    } catch (err) {
      console.error(err);
      toast.error("Failed to update ads");
    }
  }

  const handleFrequencyChange = (e) => {
    let value = e.target.value;

    if (value === "") {
      value = 0;
    }

    if (value >= 0 && value <= 100) {
      setFrequency(value);
      if (value !== adsControl.interstatialAdsFrequency) {
        setIsSubmitEnabled(true);
      } else {
        setIsSubmitEnabled(false);
      }
    }
  };

  const handleSubmit = () => {
    updateIndividual("frequency");
  };

  return (
    <>
      <Heading>Control Ads</Heading>

      {/* ==== custom table ==== */}
      <div
        className="table-responsive"
        style={{
          borderRadius: "14px",
          opacity: paginationLoader && "50%",
          pointerEvents: paginationLoader && "none",
          position: "relative",
        }}
      >
        {paginationLoader && (
          <TableLoaderDiv>
            <SkeletonLoader />
          </TableLoaderDiv>
        )}
        <BoxContainer>
          <Grid container style={{ alignContent: "center" }}>
            <Grid item xs={3.4}>
              <HeadText>Title</HeadText>
            </Grid>

            <Grid item xs={3.4}>
              <HeadText>Enable/Disable</HeadText>
            </Grid>

            <Grid item xs={2.4}>
              <HeadText>Status</HeadText>
            </Grid>
          </Grid>
        </BoxContainer>

        {Object.keys(adsControl).length > 0 ? (
          <>
            <CardBody
              sx={{
                borderRadius: `${
                  adsControl.showAds ? "" : "0px 0px 14px 14px"
                }`,
              }}
            >
              <Grid container style={{ display: "flex", alignItems: "center" }}>
                <Grid item xs={3.4}>
                  <Box
                    sx={{
                      flex: 1,
                      minWidth: 0,
                    }}
                  >
                    <RowText>All Ads</RowText>
                  </Box>
                </Grid>
                <Grid item xs={3.4}>
                  <Box
                    sx={{
                      flex: 1,
                      minWidth: 0,
                    }}
                  >
                    <RowText>
                      <SwitchButton
                        checked={adsControl.showAds ? true : false}
                        onClick={() => getOrUpdateAdsControl(true)}
                      />
                    </RowText>
                  </Box>
                </Grid>
                <Grid item xs={2.4}>
                  <Box
                    sx={{
                      flex: 1,
                      minWidth: 0,
                    }}
                  >
                    <RowText>
                      {adsControl.showAds === true ? "Enabled" : "Disabled"}
                    </RowText>
                  </Box>
                </Grid>
              </Grid>
            </CardBody>
            {adsControl.showAds ? (
              <>
                <CardBody>
                  <Grid
                    container
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Grid item xs={3.4}>
                      <Box
                        sx={{
                          flex: 1,
                          minWidth: 0,
                        }}
                      >
                        <RowText>Banner Ads</RowText>
                      </Box>
                    </Grid>
                    <Grid item xs={3.4}>
                      <Box
                        sx={{
                          flex: 1,
                          minWidth: 0,
                        }}
                      >
                        <RowText>
                          <SwitchButton
                            checked={adsControl.showBannerAds ? true : false}
                            onClick={() => updateIndividual("banner")}
                            disabled={!adsControl.showAds}
                          />
                        </RowText>
                      </Box>
                    </Grid>
                    <Grid item xs={3.4}>
                      <Box
                        sx={{
                          flex: 1,
                          minWidth: 0,
                        }}
                      >
                        <RowText>
                          {adsControl.showBannerAds === true
                            ? "Enabled"
                            : "Disabled"}
                        </RowText>
                      </Box>
                    </Grid>
                  </Grid>
                </CardBody>
                <CardBody>
                  <Grid
                    container
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Grid item xs={3.4}>
                      <Box
                        sx={{
                          flex: 1,
                          minWidth: 0,
                        }}
                      >
                        <RowText>Interstatial Ads</RowText>
                      </Box>
                    </Grid>
                    <Grid item xs={3.4}>
                      <Box
                        sx={{
                          flex: 1,
                          minWidth: 0,
                        }}
                      >
                        <RowText>
                          <SwitchButton
                            checked={
                              adsControl.showInterstatialAds ? true : false
                            }
                            onClick={() => updateIndividual("interStatial")}
                            disabled={!adsControl.showAds}
                          />
                        </RowText>
                      </Box>
                    </Grid>
                    <Grid item xs={2.4}>
                      <Box
                        sx={{
                          flex: 1,
                          minWidth: 0,
                        }}
                      >
                        <RowText>
                          {adsControl.showInterstatialAds === true
                            ? "Enabled"
                            : "Disabled"}
                        </RowText>
                      </Box>
                    </Grid>
                  </Grid>
                </CardBody>
                <CardBody
                  sx={{
                    borderRadius: "0px 0px 14px 14px",
                  }}
                >
                  <Grid
                    container
                    style={{ display: "flex", alignItems: "center" }}
                  >
                    <Grid item xs={3.4}>
                      <Box
                        sx={{
                          flex: 1,
                          minWidth: 0,
                        }}
                      >
                        <RowText>Interstatial Ads Frequency</RowText>
                      </Box>
                    </Grid>
                    <Grid item xs={3.4}>
                      <Box
                        sx={{
                          flex: 1,
                          minWidth: 0,
                          display: "flex",
                          flexDirection: "row",
                        }}
                      >
                        <RowText>
                          <TextField
                            type="number"
                            value={frequency} // Bind input to local state
                            onChange={handleFrequencyChange} // Handle change to update local state
                            inputProps={{ min: 0, max: 100 }}
                            size="small"
                            sx={{ width: "80px" }} // Small width for input box
                            disabled={
                              !adsControl.showAds ||
                              !adsControl.showInterstatialAds
                            }
                          />
                        </RowText>

                        <Button
                          variant="contained" // Button style
                          size="small"
                          onClick={handleSubmit} // Function to call when button is pressed
                          sx={{ ml: 2 }}
                          disabled={
                            !adsControl.showAds ||
                            !adsControl.showInterstatialAds ||
                            !isSubmitEnabled
                          } // Left margin to separate from the input
                        >
                          Submit
                        </Button>
                      </Box>
                    </Grid>
                  </Grid>
                </CardBody>
              </>
            ) : null}
          </>
        ) : (
          <CardBody
            sx={{
              borderRadius: "0px 0px 14px 14px",
            }}
          >
            Loading ads configuration...
          </CardBody>
        )}
      </div>
    </>
  );
}

export default ControlAds;

const TableLoaderDiv = styled(Box)(() => ({
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  zIndex: 9999,
}));
const BoxContainer = styled(Box)(() => ({
  marginTop: "30px",
  height: "49px",
  width: "100%",
  border: "0.6px solid #D5D5D5",
  background: "#FCFDFD",
  borderRadius: "14px 14px 0px 0px",
  display: "flex",
  justifyContent: "space-around",
  padding: "12px 42px",
  minWidth: "500px",
}));
const HeadText = styled(Typography)(() => ({
  fontWeight: "800",
  fontSize: "14px",
  lineHeight: "17px",
  color: "#202224",
  opacity: "90%",
}));
const Heading = styled(Box)(() => ({
  fontSize: "32px",
  fontWeight: "600",
  color: "#333333",
  marginTop: "10px",
}));
const CardBody = styled(Box)(() => ({
  boxShadow: "0px 2px 8px rgba(2, 0, 1, 0.1)",
  padding: "0px 42px",
  width: "100%",
  height: "86px",
  backgroundColor: "#FFFFFF",
  border: "0.6px solid #D5D5D5",
  borderTop: "none",
  borderBottom: "0.6px solid #D5D5D5",
  display: "flex",
  alignItems: "center",
  minWidth: "500px",
  // overflowX: 'auto'
}));
const RowText = styled(Typography)(() => ({
  fontStyle: "normal",
  fontWeight: "500",
  fontSize: "14px",
  lineHeight: "24px",
  color: "#7C3F92",
  maxWidth: "90%",
  overflow: "hidden", // Prevent text overflow
  whiteSpace: "nowrap", // Prevent text wrapping
  textOverflow: "ellipsis", // Show ellipsis for overflow
}));

const SwitchButton = styled(Switch)(({ theme }) => ({
  "& .MuiSwitch-switchBase.Mui-checked": {
    color: "#6200EE",
    "&:hover": {
      backgroundColor: alpha("#6200EE", theme.palette.action.hoverOpacity),
    },
  },
  "& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track": {
    backgroundColor: "#6200EE",
  },
}));
