import axios from "axios";

const BASE_URL = "https://api.pencil.pk";

export const apiServices = async (type, endpoint, data, state, header_data) => {
  let athtoken = state?.token;

  if (type === "GET") {
    try {
      let result = axios({
        url: `${BASE_URL}/${endpoint}`,
        method: "GET",
        headers: {
          "Content-Type": "application/json",
          Authorization: athtoken,
          Accept: "application/json",
          ...header_data,
        },
        responseType: "",
      }).then((res) => res);
      return result;
    } catch (error) {
      console.error("GET API FAILED !");
    }
  } else if (type === "PUT") {
    try {
      let result = axios({
        url: `${BASE_URL}/${endpoint}`,
        method: "PUT",
        headers: {
          "Content-Type": "application/json",
          Authorization: athtoken,
          Accept: "application/json",
        },
        data: data,
      }).then((res) => res);
      return result;
    } catch (error) {
      console.log("GET Api Failed");
    }
  } else if (type === "DELETE") {
    try {
      let result = axios({
        url: `${BASE_URL}/${endpoint}`,
        method: "DELETE",
        headers: {
          "Content-Type": "application/json",
          Authorization: athtoken,
          Accept: "application/json",
        },
        data: {
          _id: data,
        },
      }).then((res) => res);
      return result;
    } catch (error) {
      console.log("Delete Api Failed");
    }
  } else {
    try {
      let result = axios({
        url: `${BASE_URL}/${endpoint}`,
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: athtoken,
          Accept: "application/json",
          withCredentials: true,
        },
        data: data,
        // data:  {...data, companyID: company_id}
      }).then((res) => res);
      return result;
    } catch (error) {
      console.error("POST API FAILED!");
    }
  }
};

export { BASE_URL };
