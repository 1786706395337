import React from "react";
import { Route, Routes } from "react-router-dom";
import Login from "./Pages/Login/Login";
import Dashboard from "./Pages/Dashboard/DashboardScreen";
import Layout from "./Pages/Layout";
import RequireAuth from "./Pages/RequireAuth";
import Agencies from "./Pages/Dashboard/Agencies";
import Users from "./Pages/Dashboard/Users";
import Settings from "./Pages/Dashboard/Settings";
import ControlAds from "./Pages/Dashboard/ControlAds";
import { Box, CssBaseline } from "@mui/material";
import { Toaster } from "react-hot-toast";
import { useSelector } from "react-redux";
import PageNotFound from "./Pages/PageNotFound";
import NewsArticles from "./Pages/Dashboard/NewsArticles";

function App() {
  const { success } = useSelector((state) => state?.auth);

  return (
    <div className="App">
      <CssBaseline />
      <Box>
        <Toaster />
      </Box>
      <Routes>
        {/* === Public Routes === */}
        <Route path="/" element={<Login />} />
        <Route path="/login" element={<Login />} />

        {/* === Private Routes === */}
        <Route path="/" element={<Layout />}>
          <Route element={<RequireAuth />}>
            {success && (
              <>
                <Route path="dashboard" element={<Dashboard />} />
                <Route path="agencies" element={<Agencies />} />
                <Route path="users" element={<Users />} />
                <Route path="news-articles" element={<NewsArticles />} />
                <Route path="control-ads" element={<ControlAds />} />
                <Route path="settings" element={<Settings />} />
              </>
            )}
          </Route>
        </Route>

        <Route path="*" element={<PageNotFound />} />
      </Routes>
    </div>
  );
}

export default App;
