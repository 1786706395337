import { Box, Grid, Switch, Typography } from '@mui/material';
import { alpha, styled } from '@mui/material/styles';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { apiServices } from '../Services/apiServices';
import toast from 'react-hot-toast';

function UserCard({ data, index, userData, currentPage, pageSize }) {
    const auth_state = useSelector(state => state?.auth);

    const [swicthCheck, setSwicthCheck] = useState(!data?.deleted)

    const handleSwitchChange = (event) => {
        setSwicthCheck(event.target.checked);
        apiServices("PUT", `api/admin/users/${data?._id}`, {deleted: !event.target.checked}, auth_state)
        .then((res) => {
            if (res?.data?.success) {
                event.target.checked ? toast.success("User Enabled Successfully!") : toast.success("User Disabled Successfully!")
            }
        })
        .catch((err) => {
            toast.error(
                `${
                err?.response?.data?.msg
                    ? err?.response?.data?.msg
                    : err?.response?.data?.validation?.body?.message
                    ? err?.response?.data?.validation?.body?.message
                    : "User Update Error"
                }!`
            );
        });
    };
    
    if(userData?.length === 0){
    return(
        <CardBody sx={{borderRadius: '0px 0px 14px 14px', height: '200px', display: 'flex', justifyContent: 'center'}}>
            No Record Found
        </CardBody>
    )}
        
  return (
    <>
        <CardBody sx={{borderRadius: (userData?.length === index+1) && '0px 0px 14px 14px'}}>
            <Grid container style={{display: 'flex', alignItems: 'center'}}>
                <Grid item xs={2.4}>
                    <Box
                        sx={{
                            flex: 1,
                            minWidth: 0
                        }}
                    >
                        <RowText>
                        {((currentPage - 1) * pageSize + index + 1).toString().padStart(2, '0')}
                        </RowText>
                    </Box>
                </Grid>
                <Grid item xs={2.4}>
                    <Box
                        sx={{
                            flex: 1,
                            minWidth: 0
                        }}
                    >
                        <RowText>
                            {data?.name}
                        </RowText>
                    </Box>
                </Grid>
                <Grid item xs={3.5}>
                    <Box
                        sx={{
                            flex: 1,
                            minWidth: 0
                        }}
                    >
                        <RowText>
                            {data?.email}
                        </RowText>
                    </Box>
                </Grid>
                <Grid item xs={2.4}>
                    <Box
                        sx={{
                            flex: 1,
                            minWidth: 0
                        }}
                    >
                        <RowText>
                            {data?.type}
                        </RowText>
                    </Box>
                </Grid>
                <Grid item xs={1.3}>
                    <Box
                        sx={{
                            flex: 1,
                            minWidth: 0
                        }}
                    >
                        <SwitchButton
                            checked={swicthCheck}
                            onChange={handleSwitchChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                    </Box>
                </Grid>
            </Grid>
        </CardBody>
    </>
  )
}

export default UserCard;

const RowText = styled(Typography)(() => ({
    fontStyle: 'normal',
    fontWeight: '500',
    fontSize: '14px',
    lineHeight: '24px',
    color: '#7C3F92',
    maxWidth: '90%',
    overflow: 'hidden', // Prevent text overflow
    whiteSpace: 'nowrap', // Prevent text wrapping
    textOverflow: 'ellipsis' // Show ellipsis for overflow
  }));
const CardBody = styled(Box)(() => ({
    boxShadow: '0px 2px 8px rgba(2, 0, 1, 0.1)',
    padding: '0px 42px',
    width: '100%',
    height: '86px',
    backgroundColor: '#FFFFFF',
    border: '0.6px solid #D5D5D5',
    borderTop: 'none',
    borderBottom: '0.6px solid #D5D5D5',
    display: 'flex',
    alignItems: 'center',
    minWidth: '500px',
    // overflowX: 'auto'
  }));

const SwitchButton = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: '#6200EE',
      '&:hover': {
        backgroundColor: alpha('#6200EE', theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#6200EE',
    },
  }));