import React, { useEffect, useState } from 'react';
import styled from '@emotion/styled';
import { Box } from '@mui/material';
import { ReactComponent as TotalUserIcon } from '../../Assets/icons/TotalUserIcon.svg';
import { ReactComponent as SourcesIcon } from '../../Assets/icons/SourcesIcon.svg';
import { ReactComponent as GuestIcon } from '../../Assets/icons/GuestIcon.svg';
import { apiServices } from '../../Services/apiServices';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import SkeletonLoader from '../../Components/SkeletonLoader';

const Dashboard = () => {
  const auth_state = useSelector(state => state?.auth);

  const [data, setData] = useState(null);
  const [firstRender, setFirstRender] = useState(true);

  useEffect(() => {
    getDashboardData();
  }, [])

  const getDashboardData = () => {
    apiServices("GET", "api/admin/dashboard", null, auth_state)
    .then((res) => {
      if (res?.data?.success) {
        setData(res?.data?.count);
        setFirstRender(false);
      }
    })
    .catch((err) => {
      setFirstRender(false);
      toast.error(
        `${
          err?.response?.data?.msg
            ? err?.response?.data?.msg
            : err?.response?.data?.validation?.body?.message
            ? err?.response?.data?.validation?.body?.message
            : "Getting Dashboard Info Error"
        }!`
      );
    });
  }

  if(firstRender){
    return(
      <>
        <Heading>
          Dashboard
        </Heading>
        <SkeletonLoader />
      </>
    )
  }
  
  return (
    <>
        <Heading>
            Dashboard
        </Heading>
        <CardContainer>
          <GridBox>
            <div style={{display: 'grid', gap: '65.86px'}}>
              <GridText>Total User</GridText>
              <GridNumberText>{data?.totalUsers}</GridNumberText>
            </div>
            <div><TotalUserIcon width={110} height={110} /></div>
          </GridBox>
          <GridBox>
            <div style={{display: 'grid', gap: '65.86px'}}>
              <GridText>News Sources</GridText>
              <GridNumberText>{data?.newsSourceCount}</GridNumberText>
            </div>
            <div><SourcesIcon width={110} height={110} /></div>
          </GridBox>
          <GridBox>
            <div style={{display: 'grid', gap: '65.86px'}}>
              <GridText>Guest Users</GridText>
              <GridNumberText>{data?.guestUsers}</GridNumberText>
            </div>
            <div><GuestIcon width={110} height={110} /></div>
          </GridBox>
          <GridBox>
            <div style={{display: 'grid', gap: '65.86px'}}>
              <GridText>Registered Users</GridText>
              <GridNumberText>{data?.registeredUsers}</GridNumberText>
            </div>
            <div><SourcesIcon width={110} height={110} /></div>
          </GridBox>
        </CardContainer>
    </>
  )
}

export default Dashboard;

const Heading = styled(Box)(() => ({
    fontSize: '32px',
    fontWeight: '600',
    color: '#333333',
    marginTop: '10px'
  }));
const CardContainer = styled(Box)(() => ({
  display: 'flex',
  gap: '24px',
  flexWrap: 'wrap',
  width: '100%',
  marginTop: '30px'
}));
const GridBox = styled(Box)(() => ({
    width: '48.5%',
    // height: '311px',
    height: '240px',
    background: "#FFFFFF",
    boxShadow: '7.5px 7.5px 67.5px 0px #0000000D',
    borderRadius: '15px',
    display: 'flex',
    padding: '52.52px',
    alignItems: 'center',
    justifyContent: 'space-between'
  }));
const GridText = styled(Box)(() => ({
  // fontSize: '28px',
  fontSize: 'clamp(20px, 3vw, 25px)',
  fontWeight: 600,
  lineHeight: '34px',
  letterSpacing: '0px',
  color: '#202224',
  opacity: '70%'
  }));
const GridNumberText = styled(Box)(() => ({
  // fontSize: '48px',
  fontSize: '40px',
  fontWeight: 700,
  lineHeight: '34px',
  letterSpacing: '0px',
  textAlign: 'left',
  color: '#202224'
  }));