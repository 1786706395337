import styled from '@emotion/styled';
import { Box, Grid, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react'
import UserCard from '../../Components/UserCard';
import { Pagination } from 'antd';
import { useSelector } from 'react-redux';
import { apiServices } from '../../Services/apiServices';
import toast from 'react-hot-toast';
import SkeletonLoader from '../../Components/SkeletonLoader';

function Users() {
  const auth_state = useSelector(state => state?.auth);

  const [userData, setUserData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [paginationDetail, setPaginationDetail] = useState();
  const [firstRender, setFirstRender] = useState(true);
  const [paginationLoader, setPaginationLoader] = useState(false);

  useEffect(() => {
    getUserData();
  }, [])

  const getUserData = (page, size) => {
    let header_data = {
        'x-pagination-page': page ? page : currentPage,
        'x-pagination-limit': size ? size : pageSize
    }
    apiServices("GET", "api/admin/users", null, auth_state, header_data)
    .then((res) => {
      if (res?.data?.success) {
        setUserData(res?.data?.users?.docs);
        setPaginationDetail(res?.data?.users);
        setFirstRender(false);
        setPaginationLoader(false);
      }
    })
    .catch((err) => {
      setFirstRender(false);
      setPaginationLoader(false);
      toast.error(
        `${
          err?.response?.data?.msg
            ? err?.response?.data?.msg
            : err?.response?.data?.validation?.body?.message
            ? err?.response?.data?.validation?.body?.message
            : "Getting Users Info Error"
        }!`
      );
    });
  }

  if(firstRender){
    return(
      <>
        <Heading>
            Users
        </Heading>
        <SkeletonLoader />
      </>
    )
  }



  return (
    <>
        <Heading>
            Users
        </Heading>

        {/* ==== custom table ==== */}
        <div className="table-responsive" style={{borderRadius: '14px', opacity: paginationLoader && '50%', pointerEvents: paginationLoader && 'none', position: 'relative'}}>
          {paginationLoader && (
            <TableLoaderDiv>
              <SkeletonLoader />
            </TableLoaderDiv>
          )}
          <BoxContainer>
            <Grid container style={{alignContent: 'center'}}>
              <Grid item xs={2.4}>
                <HeadText>
                  ID
                </HeadText>
              </Grid>

              <Grid item xs={2.4}>
                <HeadText>
                  Name
                </HeadText>
              </Grid>

              <Grid item xs={3.5}>
                <HeadText>
                  Email
                </HeadText>
              </Grid>
              <Grid item xs={2.4}>
                <HeadText>
                  Status
                </HeadText>
              </Grid>

              <Grid item xs={1.3}>
                <HeadText>
                  Action
                </HeadText>
              </Grid>
            </Grid>
          </BoxContainer>

            {
              userData?.length > 0 ?
              userData?.map((value, index) => (
                <UserCard
                  key={index}
                  index={index}
                  data={value}
                  userData={userData}
                  currentPage={currentPage}
                  pageSize={pageSize}
                />
              )) :
              <UserCard userData={userData} />
            }
        </div>

        {/* ==== custom Pagination ==== */}
        {
          userData?.length > 0 &&
          <div>
            <Pagination
              style={{display: 'flex', float: 'right', margin: '50px 0px', fontWeight: '500'}}
              total={paginationDetail?.totalDocs}
              pageSize={pageSize}
              current={currentPage}
              showTotal={(total, range) =>
                `Showing ${range[0]} to ${range[1]} of ${total} entries`
              }
              showSizeChanger={false}
              onChange={(page, size) => {
                setPageSize(size); setCurrentPage(page);
                getUserData(page, size);
                setPaginationLoader(true);
              }}
            />
          </div>
        }
    </>
  )
}

export default Users;

const TableLoaderDiv = styled(Box)(() => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 9999
}));
const BoxContainer = styled(Box)(() => ({
  marginTop: '30px',
  height: '49px',
  width: '100%',
  border: '0.6px solid #D5D5D5',
  background: '#FCFDFD',
  borderRadius: '14px 14px 0px 0px',
  display: 'flex',
  justifyContent: 'space-around',
  padding: '12px 42px',
  minWidth: '500px',
}));
const HeadText = styled(Typography)(() => ({
  fontWeight: '800',
  fontSize: '14px',
  lineHeight: '17px',
  color: '#202224',
  opacity: '90%'
}));
const Heading = styled(Box)(() => ({
  fontSize: '32px',
  fontWeight: '600',
  color: '#333333',
  marginTop: '10px'
}));