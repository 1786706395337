import styled from '@emotion/styled';
import { Box, Grid, Typography } from '@mui/material';
import React, { useState, useEffect } from 'react'
import { Pagination } from 'antd';
import { useSelector } from 'react-redux';
import { apiServices } from '../../Services/apiServices';
import toast from 'react-hot-toast';
import SkeletonLoader from '../../Components/SkeletonLoader';
import NewsCard from '../../Components/NewsCard';

function NewsArticles() {
  const auth_state = useSelector(state => state?.auth);

  const [newsData, setNewsData] = useState([]);
  const [currentPage, setCurrentPage] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [paginationDetail, setPaginationDetail] = useState();
  const [firstRender, setFirstRender] = useState(true);
  const [paginationLoader, setPaginationLoader] = useState(false);

  let data = [
    {
        _id: 1,
        source: 'AAJ News',
        description: 'It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged.',
        date: '2024-01-09T05:26:15.800Z'
    },
    {
        _id: 2,
        source: 'ABN News',
        description: 'Simply dummy text of the printing and typesetting industry.',
        date: '2024-02-10T05:26:15.800Z'
    },
    {
        _id: 3,
        source: 'BBC Urdu',
        description: "It has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book.",
        date: '2024-03-11T05:26:15.800Z'
    },
    {
        _id: 4,
        source: 'TRT Urdu',
        description: "Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry's standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen book. It has survived not only five centuries, but also the leap into electronic typesetting, remaining essentially unchanged. It was popularised in the 1960s with the release of Letraset sheets containing Lorem Ipsum passages, and more recently with desktop publishing software like Aldus PageMaker including versions of Lorem Ipsum. Lorem Ipsum is simply dummy text of the printing and typesetting industry.",
        date: '2025-06-09T05:26:15.800Z'
    },
  ]

  useEffect(() => {
    // getNewssData();
    setNewsData(data);
    setFirstRender(false);
    setPaginationLoader(false);
  }, [])

  const getNewssData = (page, size) => {
    let header_data = {
        'x-pagination-page': page ? page : currentPage,
        'x-pagination-limit': size ? size : pageSize
    }
    apiServices("GET", "api/admin/users", null, auth_state, header_data)
    .then((res) => {
      if (res?.data?.success) {
        setNewsData(res?.data?.users?.docs);
        setPaginationDetail(res?.data?.users);
        setFirstRender(false);
        setPaginationLoader(false);
      }
    })
    .catch((err) => {
      setFirstRender(false);
      setPaginationLoader(false);
      toast.error(
        `${
          err?.response?.data?.msg
            ? err?.response?.data?.msg
            : err?.response?.data?.validation?.body?.message
            ? err?.response?.data?.validation?.body?.message
            : "Getting Users Info Error"
        }!`
      );
    });
  }

  if(firstRender){
    return(
      <>
        <Heading>
            News Articles
        </Heading>
        <SkeletonLoader />
      </>
    )
  }



  return (
    <>
        <Heading>
            News Articles
        </Heading>

        {/* ==== custom table ==== */}
        <div className="table-responsive" style={{borderRadius: '14px', opacity: paginationLoader && '50%', pointerEvents: paginationLoader && 'none', position: 'relative'}}>
          {paginationLoader && (
            <TableLoaderDiv>
              <SkeletonLoader />
            </TableLoaderDiv>
          )}
          <BoxContainer>
            <Grid container style={{alignContent: 'center'}}>
              <Grid item xs={2.4}>
                <HeadText>
                  ID
                </HeadText>
              </Grid>

              <Grid item xs={2.4}>
                <HeadText>
                  Source
                </HeadText>
              </Grid>

              <Grid item xs={3.5}>
                <HeadText>
                  Description
                </HeadText>
              </Grid>
              <Grid item xs={2.4}>
                <HeadText>
                  Date
                </HeadText>
              </Grid>

              <Grid item xs={1.3}>
                <HeadText>
                  Action
                </HeadText>
              </Grid>
            </Grid>
          </BoxContainer>

            {
              newsData?.length > 0 ?
              newsData?.map((value, index) => (
                <NewsCard
                  key={index}
                  index={index}
                  data={value}
                  newsData={newsData}
                  currentPage={currentPage}
                  pageSize={pageSize}
                />
              )) :
              <NewsCard newsData={newsData} />
            }
        </div>

        {/* ==== custom Pagination ==== */}
        {
          newsData?.length > 0 &&
          <div>
            <Pagination
              style={{display: 'flex', float: 'right', margin: '50px 0px', fontWeight: '500'}}
              total={paginationDetail?.totalDocs}
              pageSize={pageSize}
              current={currentPage}
              showTotal={(total, range) =>
                `Showing ${range[0]} to ${range[1]} of ${total} entries`
              }
              showSizeChanger={false}
              onChange={(page, size) => {
                setPageSize(size); setCurrentPage(page);
                getNewssData(page, size);
                setPaginationLoader(true);
              }}
            />
          </div>
        }
    </>
  )
}

export default NewsArticles;

const TableLoaderDiv = styled(Box)(() => ({
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  zIndex: 9999
}));
const BoxContainer = styled(Box)(() => ({
  marginTop: '30px',
  height: '49px',
  width: '100%',
  border: '0.6px solid #D5D5D5',
  background: '#FCFDFD',
  borderRadius: '14px 14px 0px 0px',
  display: 'flex',
  justifyContent: 'space-around',
  padding: '12px 42px',
  minWidth: '500px',
}));
const HeadText = styled(Typography)(() => ({
  fontWeight: '800',
  fontSize: '14px',
  lineHeight: '17px',
  color: '#202224',
  opacity: '90%'
}));
const Heading = styled(Box)(() => ({
  fontSize: '32px',
  fontWeight: '600',
  color: '#333333',
  marginTop: '10px'
}));