
import { Switch, Modal, Button, Typography } from "@mui/material";
import { alpha, styled } from '@mui/material/styles';
import React, { useEffect, useState } from 'react';
import { apiServices } from "../../Services/apiServices";
import moment from "moment";
import { TextareaAutosize as BaseTextareaAutosize } from '@mui/base/TextareaAutosize';
import UserImage from '../../Assets/images/UserImage.png';
import { CancelRounded } from "@mui/icons-material";


function ViewNewsModal({ open, setOpen, data, id }) {

    const [swicthCheck, setSwicthCheck] = useState();

    const handleSwitchChange = (event) => {
        setSwicthCheck(event.target.checked);
        // apiServices("PUT", `api/admin/users/${data?._id}`, {deleted: !event.target.checked}, auth_state)
        // .then((res) => {
        //     if (res?.data?.success) {
        //         event.target.checked ? toast.success("User Enabled Successfully!") : toast.success("User Disabled Successfully!")
        //     }
        // })
        // .catch((err) => {
        //     toast.error(
        //         `${
        //         err?.response?.data?.msg
        //             ? err?.response?.data?.msg
        //             : err?.response?.data?.validation?.body?.message
        //             ? err?.response?.data?.validation?.body?.message
        //             : "User Update Error"
        //         }!`
        //     );
        // });
    };


  return (
    <>
        <Modal
            open={open}
            onClose={() => setOpen(false)}
            aria-labelledby="modal-modal-title"
            className="modalScroll"
            aria-describedby="modal-modal-description"
            disableRestoreFocus
            // BackdropProps={{
            // style: { backgroundColor: "rgb(0 0 0 / 50%)" }, // Set the backdrop color here
            // }}
            sx={{
                // overflowY: "scroll"
            }}
        >
            <div className="modal-dialog modal-dialog-centered modal-lg" style={{maxWidth: '600px', overflowY: 'auto', borderRadius: '14px'}} role="document">
                <div className="modal-content" style={{borderRadius: '14px'}}>

                    <div className="modal-header">
                        <h5 className="modal-title mx-auto" style={{lineHeight: '38px', fontWeight: '600'}}>News Detail</h5>
                        <SwitchButton
                            checked={swicthCheck}
                            onChange={handleSwitchChange}
                            inputProps={{ 'aria-label': 'controlled' }}
                        />
                        {/* <CancelRounded sx={{color: '#553e97', cursor: 'pointer', margin: '7px -7px 0px 0px'}} /> */}
                    </div>

                    <div className="modal-body" style={{padding: '30px 28px', maxHeight: '82vh', overflowY: 'auto'}}>
                        <HeadText>
                            {id}
                        </HeadText>
                        <HeadText>
                            <img src={UserImage} width={44} height={44} style={{borderRadius: '7px', marginRight: '10px'}} />
                            {data?.source}
                        </HeadText>
                        <HeadText>
                            {moment(data?.date)?.local().format('DD MMM, YYYY')}
                        </HeadText>

                        <HeadTextDesc>
                            Description:
                        </HeadTextDesc>
                        <Textarea aria-label="minimum height" minRows={6} readOnly defaultValue={data?.description} placeholder="text" />
                    </div>
                </div>
            </div>
        </Modal>
    </>
  )
}

export default ViewNewsModal;

const HeadText = styled(Typography)(() => ({
    fontWeight: '500',
    fontSize: '15px',
    lineHeight: '24px',
    color: '#202224',
    opacity: '90%',
    marginBottom: '10px'
  }));
const HeadTextDesc = styled(Typography)(() => ({
    fontWeight: '600',
    fontSize: '15px',
    lineHeight: '24px',
    color: '#202224',
    opacity: '90%',
    marginTop: '25px',
    marginBottom: '10px',
  }));
const SwitchButton = styled(Switch)(({ theme }) => ({
    '& .MuiSwitch-switchBase.Mui-checked': {
      color: '#6200EE',
      '&:hover': {
        backgroundColor: alpha('#6200EE', theme.palette.action.hoverOpacity),
      },
    },
    '& .MuiSwitch-switchBase.Mui-checked + .MuiSwitch-track': {
      backgroundColor: '#6200EE',
    },
  }));

  const grey = {
    50: '#F3F6F9',
    200: '#DAE2ED',
  };

  const Textarea = styled(BaseTextareaAutosize)(
    () => `
    box-sizing: border-box;
    width: 100%;
    font-size: 0.9rem;
    line-height: 1.8;
    padding: 8px 12px;
    border-radius: 8px;
    border: 1px solid ${grey[200]};
    box-shadow: 0px 2px 2px ${grey[50]};
    resize: none;

    &:hover {
      border-color: ${grey[200]};
    }

    &:focus {
      border-color: ${grey[200]};
    }

    // firefox
    &:focus-visible {
      outline: 0;
    }
  `,
  );